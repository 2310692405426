/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-01-15 14:50:16
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { getSession } from '../../utils';
import { AdminWrap } from './style';
import HeaderItem from '../../components/headerItem';
import LeftMenus from '../../components/leftMenus';
import RouteContent from '../../components/routeContent';

class Admin extends PureComponent {
    render() {
        const token = JSON.parse(getSession('xlwytoken'));
        if (!token) {
            return <Redirect to="/login" />
        }
        return (
            <>
                <HeaderItem account={token.account} />
                <AdminWrap>
                    <LeftMenus />
                    <RouteContent />
                </AdminWrap>
            </>
        )
    }
}

export default Admin;