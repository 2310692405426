/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-03-16 15:33:10
 * @Description: 
 */
import { message } from 'antd';
import axios from 'axios';
import { getSession, removeSession } from '../utils/';
import apiUrl from './url';

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 5000
})

instance.defaults.headers.post['Content-Type'] = 'application/json'

// 请求拦截器
instance.interceptors.request.use((config) => {
    const token = JSON.parse(getSession('xlwytoken') || null);
    if (token) {
        config.headers.common['token'] = token.token;
    }
    return config
}, (err) => {
    return Promise.reject(err)
})

// 响应拦截器
instance.interceptors.response.use((response) => {
    const data = response.data;
    if (data.hasOwnProperty('code') && data.code !== 0) {
        message.error(data.msg);
        if (data.code === 2) {
            removeSession('xlwytoken');
            window.location.href = '/#/login';
        }
    }
    return data;
}, (err) => {console.log(err);
    return Promise.reject(err)
})

const http = {
    get(url, params={}) {
        return new Promise((resolve, reject) => {
            instance({
                url,
                params,
                responseType: params.responseType || "json"
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    post(url, data={}) {
        return new Promise((resolve, reject) => {
            instance({
                method: "post",
                url,
                data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    put(url, data={}) {
        return new Promise((resolve, reject) => {
            instance({
                method: "put",
                url,
                data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    delete(url, data={}) {
        return new Promise((resolve, reject) => {
            instance({
                method: "delete",
                url,
                data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default http