const initialState = {
    collapsed: false
}

function Reducer(state = initialState, action) {
    let newState;
    switch(action.type) {
        case 'operateSide':
            newState = Object.assign({}, state)
            newState.collapsed = !newState.collapsed
            return newState;
        default:
            return state
    }
}

export default Reducer