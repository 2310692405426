/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:21
 * @LastEditTime: 2021-04-07 10:48:53
 * @Description: 
 */
import { getSession } from '../utils';
import {
    HomeOutlined,
    AppstoreOutlined,
    ApartmentOutlined,
    DollarCircleOutlined,
    UsergroupAddOutlined,
    WhatsAppOutlined,
    UserAddOutlined,
    DeploymentUnitOutlined
  } from '@ant-design/icons';
import { lazy } from 'react';

// 后面做动态路由菜单的时候好匹配
const DATA = {
    "Home": lazy(() => import("../pages/home")),
    "CompanyInfoView": lazy(() => import("../pages/companyinfo/view")),
    "ApplyControlList": lazy(() => import("../pages/applycontrol/list")),
    "RechargeControlList": lazy(() => import("../pages/rechargecontrol/list")),
    "CustomerControlList": lazy(() => import("../pages/customercontrol/list")),
    "CallRecordList": lazy(() => import("../pages/callrecord/list")),
    "UserControlList": lazy(() => import("../pages/usercontrol/list")),
    "UserControlPort": lazy(() => import("../pages/usercontrol/port")),
    "AdminControlCompanyList": lazy(() => import("../pages/admincontrol/viewallcompany")),
    "AdminControlUserList": lazy(() => import("../pages/admincontrol/viewalluser")),
    "AdminControlBillList": lazy(() => import("../pages/admincontrol/viewallbill")),
    "AdminControlSettleList": lazy(() => import("../pages/admincontrol/viewallsettle")),
    "AdminControlPortList": lazy(() => import("../pages/admincontrol/viewallport")),
    "AdminControlRechargeList": lazy(() => import("../pages/admincontrol/viewallrecharge"))
}

const account = JSON.parse(getSession('xlwytoken') || '{}').account;

const commonData = [
    {
        title: "首页",
        path: "/",
        icon: <HomeOutlined />,
        render(props) {
            return <DATA.Home {...props} />
        }
    },
    {
        title: "公司信息",
        path: "/companyinfo",
        icon: <AppstoreOutlined />,
        children: [
            {
                title: "查看",
                path: "/companyinfo/view",
                icon: '',
                render(props) {
                    return <DATA.CompanyInfoView {...props} />
                }
            }
        ]
    },
    {
        title: "申请端口管理",
        path: "/applycontrol",
        icon: <ApartmentOutlined />,
        children: [
            {
                title: "查看",
                path: "/applycontrol/list",
                icon: '',
                render(props) {
                    return <DATA.ApplyControlList {...props} />
                }
            }
        ]
    },
    {
        title: "充值管理",
        path: "/rechargecontrol",
        icon: <DollarCircleOutlined />,
        children: [
            {
                title: "查看",
                path: "/rechargecontrol/list",
                icon: '',
                render(props) {
                    return <DATA.RechargeControlList {...props} />
                }
            }
        ]
    },
    {
        title: "客户管理",
        path: "/customercontrol",
        icon: <UsergroupAddOutlined />,
        children: [
            {
                title: "查看",
                path: "/customercontrol/list",
                icon: '',
                render(props) {
                    return <DATA.CustomerControlList {...props} />
                }
            }
        ]
    },
    {
        title: "通话管理",
        path: "/callrecord",
        icon: <WhatsAppOutlined />,
        children: [
            {
                title: "查看",
                path: "/callrecord/list",
                icon: '',
                render(props) {
                    return <DATA.CallRecordList {...props} />
                }
            }
        ]
    },
    {
        title: "用户终端管理",
        path: "/usercontrol",
        icon: <UserAddOutlined />,
        children: [
            {
                title: "查看用户",
                path: "/usercontrol/list",
                icon: '',
                render(props) {
                    return <DATA.UserControlList {...props} />
                }
            },
            {
                title: "查看终端",
                path: "/usercontrol/port",
                icon: '',
                render(props) {
                    return <DATA.UserControlPort {...props} />
                }
            }
        ]
    }
];

const adminData = [{
    title: "超级管理",
    path: "/admincontrol",
    icon: <DeploymentUnitOutlined />,
    children: [
        {
            title: "所有公司",
            path: "/admincontrol/viewallcompany",
            icon: '',
            render(props) {
                return <DATA.AdminControlCompanyList {...props} />
            }
        },
        {
            title: "所有用户",
            path: "/admincontrol/viewalluser",
            icon: '',
            render(props) {
                return <DATA.AdminControlUserList {...props} />
            }
        },
        // {
        //     title: "所有账单",
        //     path: "/admincontrol/viewallbill",
        //     icon: '',
        //     render(props) {
        //         return <DATA.AdminControlBillList {...props} />
        //     }
        // },
        {
            title: "所有结算记录",
            path: "/admincontrol/viewallsettle",
            icon: '',
            render(props) {
                return <DATA.AdminControlSettleList {...props} />
            }
        },
        {
            title: "审核端口申请",
            path: "/admincontrol/viewallport",
            icon: '',
            render(props) {
                return <DATA.AdminControlPortList {...props} />
            }
        },
        {
            title: "审核充值",
            path: "/admincontrol/viewallrecharge",
            icon: '',
            render(props) {
                return <DATA.AdminControlRechargeList {...props} />
            }
        }
    ]
}];

const routesData = account === 'admin' ? [...commonData, ...adminData] : [...commonData];

export { routesData };