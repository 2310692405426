import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import { MenuWrap } from './style';
import { routesData } from '../../router';

const { SubMenu } = Menu;
const { Sider } = Layout;

class LeftMenus extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            openKeys: [],
            selectedKeys: []
        }
    }

    RenderMenu = ({path, title, icon}) => {
        return (<Menu.Item key={path} icon={icon}>
            <Link to={path}>{title}</Link>
        </Menu.Item>)
    }

    RenderSubMenu = ({path, title, icon, children}) => {
        return (<SubMenu key={path} icon={icon} title={title}>
            {
                children.length > 0 && children.map(item => {
                    return (item.children && item.children.length > 0) ? this.RenderSubMenu(item) : this.RenderMenu(item)
                })
            }
        </SubMenu>)
    }

    // 点击 MenuItem 调用此函数
    clickItemHandle = ({key, keyPath}) => {
        this.setState({
            openKeys: [keyPath[keyPath.length-1]],
            selectedKeys: [key]
        })
    }

    // SubMenu 展开/关闭的回调
    oPenChangeHandle = (openKeys) => {
        this.setState({
            openKeys: [openKeys[openKeys.length-1]]
        })
    }

    initSelectedRouteRender() {
        const pathname = this.props.location.pathname;
        let arr = pathname.split('/');
        let openKeysStr = arr.slice(0, arr.length-1).join('/');
        this.setState({
            openKeys: [openKeysStr],
            selectedKeys: [pathname]
        })
    }

    componentDidMount() {
        this.initSelectedRouteRender()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.collapsed && this.props.collapsed) {
            this.initSelectedRouteRender()
        }
    }

    render() {
        const { openKeys, selectedKeys } = this.state;
        return (
            <MenuWrap className="scrollBarStyle">
                <Sider collapsed={this.props.collapsed}>
                    <Menu
                        onOpenChange={this.oPenChangeHandle}
                        onClick={this.clickItemHandle}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        mode="inline"
                        theme="dark"
                        >
                        {
                            routesData.length > 0 && routesData.map(item => {
                                return (item.children && item.children.length > 0) ? this.RenderSubMenu(item) : this.RenderMenu(item)
                            })
                        }
                    </Menu>
                </Sider>
            </MenuWrap>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        collapsed: state.collapsed
    }
}

export default connect(mapStateToProps, null)(withRouter(LeftMenus))