import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { routesData } from '../../router'
const routes = [];
routeRender(routesData)
function routeRender(data) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].render) {
            routes.push({
                icon: data[i].icon,
                path: data[i].path,
                title: data[i].title,
                render: data[i].render
            })
        }
        if (data[i].children && data[i].children.length > 0) {
            routeRender(data[i].children)
        }
    }
}

const RouteContent = () => {
    return (
        <div style={{ flex: 1, backgroundColor: "#eaeaea", overflow: "auto"}}>
            <Suspense fallback={<div>loading....</div>}>
                <Switch>
                    {
                        routes.length>0 && routes.map(item => (<Route key={item.path} exact path={item.path} render={item.render}></Route>))
                    }
                </Switch>
            </Suspense>
        </div>
    )
}

export default RouteContent
