/*
 * @Author: Wss
 * @Date: 2021-01-15 11:01:33
 * @LastEditTime: 2021-01-15 11:02:30
 * @Description: 
 */
import { http } from '../../config'

/**
 * 公司注册
 * name 必填
 */
export const companyAdd = (data) => {
    return http.post('/tl/company/add', data)
}