/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-01-19 10:25:56
 * @Description: 
 */
import React, { PureComponent } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import Login from './pages/login'
import Register from './pages/register'
import Admin from './pages/admin'

class App extends PureComponent {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login" component={Login}></Route>
                    <Route path="/register" component={Register}></Route>
                    <Route path="/" component={Admin}></Route>
                </Switch>
            </Router>
        )
    }
}

export default App