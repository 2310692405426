/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-03-16 15:37:54
 * @Description: 
 */
// const apiUrl = "http://47.95.249.152:8099";

const NODE_ENV = process.env.NODE_ENV;
let apiUrl;
if (NODE_ENV === 'development') {
  apiUrl = "http://39.105.115.237:8082";
} else {
  apiUrl = "http://39.105.115.237:8082";
};

export default apiUrl;