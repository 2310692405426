/*
 * @Author: Wss
 * @Date: 2021-01-26 10:11:05
 * @LastEditTime: 2021-04-08 11:49:43
 * @Description: 
 */
import moment from 'moment'

/**
 * 默认参数 "YYYY-MM-DD HH:mm:ss"
 */
function format(str, format="YYYY-MM-DD HH:mm:ss") {
    return moment(str).format(format)
}

/**
 * 将毫秒转换为 XX天XX时XX分XX秒
 */
function toDayTime(time) {
    const tempTime = moment.duration(time);
    const day = tempTime.days();
    const hour = tempTime.hours();
    const minute = tempTime.minutes();
    const second = tempTime.seconds();
    return `${addZero(day)}天${addZero(hour)}时${addZero(minute)}分${addZero(second)}秒`;
}

function addZero(n) {
    return n >= 10 ? n : '0'+n;
}

export {
    format,
    toDayTime
}

// 手写实现时间格式
// function dateFormat(date, format="YYYY-MM-DD HH:mm:ss") {
//     const config = {
//         YYYY: date.getFullYear(),
//         MM: date.getMonth() + 1,
//         DD: date.getDate(),
//         HH: date.getHours(),
//         mm: date.getMinutes(),
//         ss: date.getSeconds()
//     }
//     for (let key in config) {
//         format = format.replace(key, config[key])
//     }
//     return format
// }

// console.log( dateFormat(new Date('2020-11-28T17:09:48.537')) )