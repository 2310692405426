/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-04-07 15:15:06
 * @Description: 
 */
import { http } from '../../config'

/**
 * 用户登录
 * account 必填
 * password 必填
 */
export const userLogin = (data) => {
    return http.post('/tl/login/login', data)
}

/**
 * 用户登出
 */
export const userLogout = (data) => {
    return http.post('/tl/login/logout', data)
}

/**
 * 首页数据统计
 */
export const companyCount = () => {
    return http.post('/tl/company/count')
}