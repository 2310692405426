/*
 * @Author: Wss
 * @Date: 2021-01-26 10:11:05
 * @LastEditTime: 2021-04-07 11:21:10
 * @Description: 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { GlobalStyle } from './assets/styles';
import 'antd/dist/antd.css';
import App from './app';

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>
  ,
  document.getElementById('root')
);

