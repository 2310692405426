import styled from 'styled-components'

export const MenuWrap = styled.div`
    background-color: rgb(48,54,62);
    overflow: auto;
    .ant-menu {
        background-color: rgb(48,54,62)!important;
    }
    .ant-menu-sub {
        background-color: rgb(48,54,62)!important;
    }
    .ant-menu-item-selected {
        background-color: rgba(0,0,0,.5)!important;
        box-shadow: inset 4px 0 0 0 #1890ff;
    }
`