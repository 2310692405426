/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-03-24 17:18:07
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { RegisterWrap } from './style';
import { Form, Input, Button, message, Space, Modal } from 'antd';
import { companyAdd } from '../../api/register';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class Register extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false
        };
    }

    onFinish = async (values) => {
        values.echoCount = Number(values.echoCount);
        values.terminalCount = Number(values.terminalCount);
        const data = await companyAdd(values);
        if (data.code === 0) {
            message.success('注册成功，待审核通过后可登录');
            this.props.history.push('/login');
        } else {
            message.error(data.msg);
        }
    }
    prevPageHandle = async () => {
        let result = await this.formRef.getFieldsValue(); // 获取form表单里表单元素的value
        // console.log(result);
        // console.log(result);
        let values = Object.values(result);
        let bool = values.every(item => (item === undefined || item === ''));
        if (bool) {
            // 没填写数据
            this.props.history.push('/login');
        } else {
            // 有填写数据
            this.setState({
                isModalVisible: true
            });
        }
    }
    onFinishFailed = (errInfo) => {
        console.log('Failed', errInfo);
    }
    handleOk = () => {
        this.props.history.push('/login');
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        });
    }
    render() {
        return (
            <RegisterWrap>
                <Modal title="提示" maskClosable={false} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <p>已经填写了部分数据，确认返回？</p>
                </Modal>
                <Form
                {...layout}
                name="register"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                ref={ (el) => this.formRef = el}
                onFinishFailed={this.onFinishFailed}
                >
                    <h2>公司信息注册</h2>
                    <Form.Item
                        name="account"
                        label="公司账号"
                        rules={[
                            { required: true, message: '请输入公司账号!' },
                            { min: 5, message: '公司账号不能少于5个字符！' }
                        ]}
                    >
                        <Input placeholder="公司账号，且唯一，用于登陆" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="账号密码"
                        rules={[{ required: true, message: '请输入账号密码!' }]}
                    >
                        <Input.Password placeholder="密码" />
                    </Form.Item>

                    <Form.Item 
                        name="alias" 
                        label="公司名"
                        rules={[
                            { required: true, message: '请输入公司名字!' },
                            { min: 5, message: '公司名不能少于5个字符！' }
                        ]}
                    >
                        <Input placeholder="请输入公司名" />
                    </Form.Item>

                    <Form.Item
                        name="telephone"
                        label="公司联系方式"
                        rules={[
                            { required: true, message: '请输入公司手机号联系方式!' },
                            { pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号码！' }
                        ]}
                    >
                        <Input placeholder="公司手机号" />
                    </Form.Item>

                    <Form.Item
                        name="terminalCount"
                        label="终端数量"
                        rules={[
                            { required: true, message: '请输入终端数量!' },
                            { pattern: new RegExp(/^[1-9]\d{0,}$/), message: '首位数字不能为0' }
                        ]}
                    >
                        <Input placeholder="终端数量" type="number" />
                    </Form.Item>
                    <Form.Item
                        name="echoCount"
                        label="外显号数量"
                        rules={[
                            { required: true, message: '请输入外显号数量!' },
                            { pattern: new RegExp(/^[1-9]\d{0,}$/), message: '首位数字不能为0' }
                        ]}
                    >
                        <Input placeholder="外显号数量" type="number" />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="公司地址"
                        rules={[
                            { required: true, message: '请输入公司地址!' }
                        ]}
                    >
                        <Input placeholder="公司地址" />
                    </Form.Item>

                    <Form.Item name="email" label="公司联系邮箱"
                    rules={[{ type: 'email', message: '邮箱格式不对!' }]}>
                        <Input placeholder="公司联系邮箱" />
                    </Form.Item>

                    <Form.Item name="introduction" label="公司简介">
                        <Input.TextArea rows={4} placeholder="请输入公司简介" />
                    </Form.Item>

                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Space>
                            <Button type="primary" htmlType="submit">注册</Button>
                            <Button onClick={this.prevPageHandle} style={{marginLeft: "50px"}} type="danger">返回</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </RegisterWrap>
        )
    }
}

export default Register;