import Styled from 'styled-components'

export const LoginWrap = Styled.div`
    background-color: #f2f2f2;
    height:100vh;
    #login {
        position:absolute;
        width: 300px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);
        h2 {
            color: #000;
            text-align:center;
            line-height: 80px;
            font-size: 30px;
            font-weight: 400;
        }
    }

`