/*
 * @Author: Wss
 * @Date: 2021-02-06 10:32:57
 * @LastEditTime: 2021-02-06 10:37:08
 * @Description: 
 */

const base64Util = {
  /** 通过base64字符串生成文件 */
  downloadFileByBase64: function(base64Str, fileName) {
      var myBlob = this.dataURLtoBlob(base64Str);
      var myUrl = URL.createObjectURL(myBlob);
      this.downloadFile(myUrl, fileName);
  },
  /**封装base64Str blob对象*/
  dataURLtoBlob: function (base64Str) {
      var bstr = atob(base64Str), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr]);
  },
  /**创建一个a标签，并做下载点击事件*/
  downloadFile: function (hrefUrl, fileName) {
      var a = document.createElement("a");
      a.setAttribute("href", hrefUrl);
      a.setAttribute("download", fileName);
      a.setAttribute("target", "_blank")
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
  }
}

export { base64Util };