/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-02-06 15:31:00
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeSession } from '../../utils';
import { HeaderWrap, HeaderLeft, HeaderMiddle, HeaderRight } from './style';
import { Menu, Dropdown, Modal } from 'antd';
import { DownOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import imageIcon from './images/icon.png';
import { userLogout } from '../../api/login';

class HeaderItem extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }
    operateHandle = (e) => {
        const key = e.key;
        switch (key) {
            case 'modifyPassword':
                break;
            case 'logout':
                this.setState({
                    visible: true
                })
                break;
            default:
        }
    }
    handleOk = async () => {
        let data = await userLogout();
        if (data.code === 0) {
            removeSession('xlwytoken');
            this.props.history.push('/login');
        }
    }

    handleCancel = () => {
        this.setState({
            visible: false
        });
    }

    render() {
        const { account } = this.props;
        const { visible } = this.state;
        const menu = (<Menu onClick={this.operateHandle}>
                        {/* <Menu.Item key="modifyPassword">
                            <span>修改密码</span>
                        </Menu.Item> */}
                        <Menu.Item key="logout">
                            <span>登出</span>
                        </Menu.Item>
                    </Menu>)

        return (
            <HeaderWrap>
                <HeaderLeft>
                    <img alt="" src={imageIcon} />
                    <b>线路无忧<span>管理后台</span></b>
                    <span onClick={this.props.oPenOrCloseSide} style={{cursor: "pointer"}}>
                        {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                    </span>
                </HeaderLeft>
                <HeaderMiddle />
                <HeaderRight>
                    <Dropdown overlay={menu}>
                        <span className="dropdown-link">{account} <DownOutlined /></span>
                    </Dropdown>
                </HeaderRight>
                <Modal
                    title="确定退出？"
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <p>您确定退出当前系统吗？</p>
                </Modal>
            </HeaderWrap>
        )
    }
}

const mapStatetToProps = (state) => {
    return {
        collapsed: state.collapsed
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        oPenOrCloseSide() {
            dispatch({
                type: 'operateSide'
            })
        }
    }
}

// 当组件中的props没有href、location、match等路由信息的时候，可以通过withRouter让组件的props有这些属性
// 也可以使用useLocation,useHistory
export default connect(mapStatetToProps, mapDispatchToProps)(withRouter(HeaderItem))