/*
 * @Author: Wss
 * @Date: 2021-01-15 10:40:21
 * @LastEditTime: 2021-02-24 17:43:06
 * @Description: 
 */
import Styled from 'styled-components'

export const RegisterWrap = Styled.div`
    background-color: #f2f2f2;
    #register {
        position:absolute;
        width: 400px;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        h2 {
            color: #000;
            text-align:center;
            line-height: 80px;
            font-size: 30px;
            font-weight: 400;
        }
    }

`