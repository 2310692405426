import styled from 'styled-components'

export const HeaderWrap = styled.div`
    display: flex;
    height: 60px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f6f6f6;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
`

export const HeaderLeft = styled.div`
    width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
        width: 35px;
    }
    b {
        font-weight: bold;
        span {
            margin-left: 4px;
            font-weight:normal;
        }
    }
`

export const HeaderMiddle = styled.div`
    flex: 1;
`

export const HeaderRight = styled.div`
    width: 200px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    box-sizing: border-box;
    padding-right: 30px;
    .dropdown-link {
        color: #333;
    }
`