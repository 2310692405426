/*
 * @Author: Wss
 * @Date: 2021-01-15 10:24:20
 * @LastEditTime: 2021-03-30 17:34:45
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { setSession } from '../../utils';
import { LoginWrap } from './style';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { userLogin } from '../../api/login';

class Login extends PureComponent {
    onFinish = async (values) => {
        const data = await userLogin(values);
        if (data.code === 0) {
            const loginInfo = {
                account: values.userName,
                token: data.data
            };
            setSession('xlwytoken', loginInfo);
            // this.props.history.push('/');
            window.location.href = '/';
        }
    }
    onFinishFailed = (errInfo) => {
        console.log('Failed', errInfo);
    }
    componentDidMount() {
        // 回调Refs
        this.elemInput.focus();
    }
    render() {
        return (
            <LoginWrap>
                <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                >
                    <h2>线路无忧 管理系统</h2>

                    <Form.Item
                        name="userName"
                        rules={[
                            { required: true, message: '请输入用户名!' },
                            { min: 5, message: '用户名不能少于5个字符！' }
                        ]}
                    >
                        <Input ref={(elem) => this.elemInput = elem} placeholder="用户名" prefix={<UserOutlined style={{color: "#d2d2d2"}} className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入密码!' }]}
                    >
                        <Input.Password placeholder="密码" prefix={<LockOutlined style={{color: "#d2d2d2"}} className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>登录</Button>
                        <a style={{marginTop: "20px"}} href="/#/register">没有信息，现在注册!</a>
                    </Form.Item>
                </Form>
            </LoginWrap>
        )
    }
}

export default Login;